export function RoomIntro() {
  return (
    <div className="mt-32 flex justify-center">
        <div className="rounded-full w-64 h-64 p-2 border-2 border-red-600 flex items-center justify-center">
          <div className="rounded-full w-full h-full flex flex-col items-center justify-center bg-red-600 text-white">
            <div className="text-lg mb-2">Welcome to</div>
            <div className="text-2xl">Interactive Demo</div>
          </div>
        </div>
    </div>
  )
}