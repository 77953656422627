import { Form, Button, Input } from "antd";
import { useCallback } from "react";
import { rules } from "./RoomInputRules";

export function RoomInputPage() {
  const [form] = Form.useForm();
  
  const handleSubmit = useCallback(values => {
    window.open(`/room/${values.roomId}?name=${values.name}&avatar=${values.avatar || ''}`, '_self');
  }, []);

  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <h1 className="text-blue-400 text-xl font-bold mb-6">Interactive Demo</h1>
      <Form form={form} onFinish={handleSubmit}>
        <label>Room ID <b className="text-red-400">*</b></label>
        <Form.Item name="roomId" style={{ marginBottom: '.75rem' }} rules={rules.roomId}>
          <Input placeholder="Nhập Room ID..." className="w-full" />
        </Form.Item>
        <label>Tên của bạn <b className="text-red-400">*</b></label>
        <Form.Item name="name" style={{ marginBottom: '.75rem' }} rules={rules.username}>
          <Input placeholder="Nhập tên của bạn..." className="w-full" />
        </Form.Item>
        <label>Avatar</label>
        <Form.Item name="avatar" style={{ marginBottom: '.75rem' }}>
          <Input placeholder="Nhập avatar url..." className="w-full" />
        </Form.Item>
        <Button className="w-full mt-4" shape="round" type="primary" htmlType="submit">
          Vào phòng
        </Button>
      </Form>
    </div>
  )
}