import { AppRoutes } from './AppRoutes';

export function App() {
  return (
    <div className="h-screen md:w-1/3 mx-auto p-4" style={{ width: '480px' }}>
      <div className="border rounded-lg h-full w-full bg-white">
        <AppRoutes />
      </div>
    </div>
  );
}