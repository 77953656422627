import qs from 'querystring';
import { ClientInfoDto } from '../dtos/room/ClientInfoDto';

export class ClientUtils {

  static getClientFromUrl(): ClientInfoDto {
    const params = qs.parse(window.location.search.replace('?', ''));
    const name = String(params.name);
    if (!params.name || !this.checkName(name)) {
      alert('Tên người chơi không hợp lệ');
      window.location.pathname = '/';
    }
    return {
      id: String(name),
      name: String(name),
      avatar: String(params.avatar || '')
    };
  }

  static checkName(name: string) {
    return /^[a-zA-Z0-9]+$/g.test(name);
  }
}