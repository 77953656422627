import { CloseCircleFilled } from "@ant-design/icons";
import { Checkbox } from "antd";
import Modal from "antd/lib/modal/Modal";
import { useCallback } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { PollDto } from "../../shared/dtos/poll/PollDto";
import { SelectMode } from "../../shared/enums/SelectMode";

export interface IPollProps {
  poll: PollDto;
  selectionMap?: any;
  onChange?: (pollOptionIds: number[]) => any;
  onClose?: () => any;
}

export function Poll(props: IPollProps) {
  const { poll, onChange } = props;
  const [checkedMap, setCheckedMap] = useState<any>({});

  const handleChange = useCallback((pollOptionId: number) => {
    setCheckedMap((prev: any) => {
      console.log('setCheckedMap', pollOptionId);
      let newCheckedMap: any;
      switch (poll.selectMode) {
        case SelectMode.SINGLE:
          newCheckedMap = { [pollOptionId]: !prev[pollOptionId] };
          break;
        case SelectMode.MULTIPLE:
          newCheckedMap = { ...prev, [pollOptionId]: !prev[pollOptionId] };
          break;
        default:
          return prev;
      }

      if (onChange) {
        const checkedPollOptionIds = Object
          .keys(newCheckedMap)
          .filter(k => !!newCheckedMap[k])
          .map(k => Number(k));
        // notify parent
        onChange(checkedPollOptionIds);
      }

      return newCheckedMap;
    });
  }, [onChange, poll.selectMode]);

  const getSelection = useCallback((pollOptionId: number) => {
    if (!props.selectionMap) return 0;
    return props.selectionMap[pollOptionId] || 0;
  }, [props.selectionMap]);

  const totalSelection = useMemo(() => {
    return poll.pollOptions.reduce((total, pollOption) => {
      return total + getSelection(pollOption.id);
    }, 0);
  }, [getSelection, poll]);

  return (
    <Modal 
      title="Khảo sát" 
      className="m_modal" 
      visible 
      destroyOnClose
      footer={null} 
      closeIcon={<CloseCircleFilled onClick={props.onClose} />}>
      <div className="rounded border flex flex-col items-center justify-center my-3 p-4">
        <h3 className="text-center mb-0 font-bold">{poll.title}</h3>
        <p className="text-center mt-2 mb-0">(Đã có {totalSelection} bình chọn)</p>
      </div>
      {poll.pollOptions.map(pollOption => (
        <Checkbox
          className="relative w-full rounded-lg border border-gray-300"
          style={{ padding: '0.5rem' }}
          key={pollOption.id}
          checked={checkedMap[pollOption.id]}
          onClick={() => handleChange(pollOption.id)}>
          <div className="relative" style={{ zIndex: 10 }}>{pollOption.content}</div>
          {(() => {
            const selection = getSelection(pollOption.id);
            const percent = `${Math.round(selection / totalSelection * 100)}%`;
            return selection > 0 && (
              <>
                <div
                  title={percent}
                  className={"transition-all absolute h-full top-0 left-0 rounded-lg opacity-50 bg-blue-300 hover:bg-blue-400"}
                  style={{ width: percent }}>  
                </div>
                <div className="absolute rounded-lg bg-red-500 text-white text-xs px-1" style={{ top: '10px', right: '10px' }}>
                  {percent}
                </div>
              </>
            )
          })()}
        </Checkbox>
      ))}
    </Modal>
  )
}