import { Alert, Radio, Space } from "antd";
import { useCallback } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { QuizDto } from "../../shared/dtos/quiz/QuizDto";

export interface IQuizProps {
  quiz: QuizDto;
  onChange: (ans: string) => any;
  correctAnsId?: any;
  selectionMap?: any;
  disabled?: boolean;
  score?: number;
}

export function Quiz(props: IQuizProps) {
  const [selected, setSelected] = useState<any>(null);

  const getSelection = useCallback((ansId: any) => {
    if (props.selectionMap) {
      if (!isNaN(props.selectionMap[ansId])) {
        return props.selectionMap[ansId];
      }
      if (typeof props.selectionMap[ansId] === 'object') {
        return Object.keys(props.selectionMap[ansId]).length
      }
    }
    return 0;
  }, [props.selectionMap]);

  const totalSelection = useMemo(() => {
    if (props.selectionMap) {
      return Object.keys(props.selectionMap).reduce((total, ansId) => {
        return total + getSelection(ansId);
      }, 0);
    }
    return 0;
  }, [props.selectionMap, getSelection]);

  return (
    <div>
      {props.correctAnsId && (
        <Alert
          closable
          showIcon
          type={selected === props.correctAnsId ? 'success' : 'error'}
          message={selected === props.correctAnsId ? `Yeah! Đúng roài${props.score && props.score > 0 ? `, tặng bạn ${props.score} điểm` : ""}` : 'Oh nâu! Sai mất tiu roài'} />
      )}
      <div className="rounded border flex items-center justify-center my-4 p-4">
        <h3 className="text-center mb-0 font-bold">#{props.quiz.numOrder}. {props.quiz.content}</h3>
      </div>
      <Radio.Group disabled={props.disabled} className="w-full" onChange={e => {
        setSelected(e.target.value);
        props.onChange(e.target.value)
      }}>
        <Space className="w-full" direction="vertical">
          {props.quiz.answers.map(ans => (
            <Radio 
              className={"relative w-full rounded-lg " + (ans.id === selected ? "bg-gray-200" : "bg-gray-100 hover:bg-gray-200")}
              style={{ padding: '0.5rem' }} 
              key={ans.id}
              value={ans.id}>
              <div className="relative" style={{ zIndex: 10 }}>{ans.content}</div>
              {(() => {
                const selection = getSelection(ans.id);
                return selection > 0 && (
                  <>
                    <div
                      title={`${Math.round(selection / totalSelection * 100)}%`}
                      className={"transition-all absolute h-full top-0 left-0 rounded-lg opacity-50 " + (props.correctAnsId === ans.id ? "bg-green-300 hover:bg-green-400" : "bg-gray-300 hover:bg-gray-400")}
                      style={{ width: `${Math.round(selection / totalSelection * 100)}%` }}>  
                    </div>
                    <div className="absolute rounded-lg bg-red-500 text-white text-xs px-1" style={{ top: '10px', right: '10px' }}>
                      {selection}
                    </div>
                  </>
                )
              })()}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    </div>
  )
}