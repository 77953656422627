import { EyeFilled, FireFilled, OrderedListOutlined, StarFilled } from "@ant-design/icons";
import { useState } from "react";
import { useRef } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { EventDto } from "../../shared/dtos/EventDto";
import { EventType } from "../../shared/enums/EventType";
import { QuizModeName } from "../../shared/enums/QuizMode";
import { QuizGameInfo } from "../../shared/dtos/quiz/QuizGameInfoDto";
import { Quiz } from '../quiz/Quiz';
import { QuizLeaderboard } from "../quiz/QuizLeaderboard";
import { RoomIntro } from "./RoomIntro";
import { QuizDto } from "../../shared/dtos/quiz/QuizDto";
import { KahootStateDto } from "../../shared/dtos/quiz/KahootStateDto";
import { PollDto } from "../../shared/dtos/poll/PollDto";
import { Poll } from "../poll/Poll";
import { Alert } from "antd";
import { RSAUtils } from "../../shared/utils/RSAUtils";
import { ClientUtils } from "../../shared/utils/ClientUtils";
import { ClientInfoDto } from "../../shared/dtos/room/ClientInfoDto";

type ConnectionStatus = 'connected' | 'disconnected' | 'connecting';

const connectionStatusColors = {
  'connected': 'bg-green-400',
  'disconnected': 'bg-red-400',
  'connecting': 'bg-yellow-400'
};

const connectionStatusLabel = {
  'connected': 'Đã kết nối',
  'disconnected': 'Mất kết nối',
  'connecting': 'Đang kết nối lại...'
};

export function RoomPage(props: RouteComponentProps) {
  const history = useHistory();
  const { roomId } = props.match.params as any;
  const [totalAlive, setTotalAlive] = useState(-1);
  const [alive, setAlive] = useState(true);
  const [passedQuizCount, setPassQuizCount] = useState(0);
  const [quizGameInfo, setQuizGameInfo] = useState<QuizGameInfo>();
  const [viewerCount, setViewerCount] = useState(0);
  const [quiz, setQuiz] = useState<QuizDto | null>(null);
  const [correctAnsId, setCorrectAnsId] = useState<any>();
  const [clockSecond, setClockSecond] = useState(0);
  const [quizSelectionMap, setQuizSelectionMap] = useState({});
  const [disabledQuiz, setDisabledQuiz] = useState(false);
  const [leaderboard, setLeaderboard] = useState<any>(null);
  const [showIntro, setShowIntro] = useState(true);
  const [kahootState, setKahootState] = useState<KahootStateDto>();
  const [earnedScore, setEarnedScore] = useState(-1);
  const [ws, setWs] = useState<any>();
  const [status, setStatus] = useState<ConnectionStatus>('disconnected');
  const [endGame, setEndGame] = useState(false);
  const [showGameResult, setShowGameResult] = useState(false);
  const [clientInfo, setClientInfo] = useState<ClientInfoDto | undefined>();
  const quizRef = useRef<QuizDto | null>(null);

  // poll
  const [poll, setPoll] = useState<PollDto | null>(null);
  const [pollSelectionMap, setPollSelectionMap] = useState<any>(null);

  useEffect(() => {
    setClientInfo(ClientUtils.getClientFromUrl());
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_PUBLIC_KEY) {
      RSAUtils.setPublicKey(process.env.REACT_APP_PUBLIC_KEY);
    } else {
      alert('Public key is missing');
    }
  }, []);

  useEffect(() => {
    if (clientInfo) {
      const connect = () => {
        setStatus('connecting');
  
        const wsOrigin = process.env.REACT_APP_WS_ORIGIN;
        const appId = process.env.REACT_APP_APP_ID;
        const token = RSAUtils.encrypt(JSON.stringify(clientInfo));
        const ws = new WebSocket(`${wsOrigin}?roomId=${roomId}&appId=${appId}&token=${encodeURIComponent(token)}`);
        setWs(ws);
  
        ws.onopen = function () {
          setStatus('connected');
        }
  
        ws.onclose = function (e) {
          setStatus('disconnected');
          if (window.confirm('Mất kết nối, bạn có muốn thử kết nối lại không?')) {
            setTimeout(connect, 1000);
          }
        }
  
        ws.onerror = function (e) {
          ws.close();
        }
  
        return ws;
      }

      const ws = connect();
      return () => {
        if (ws.readyState !== WebSocket.CLOSED && ws.readyState !== WebSocket.CLOSING) {
          ws.close();
          setWs(null);
          console.log("WS closed");
        }
      }
    }
  }, [clientInfo, roomId]);

  useEffect(() => {
    if (!ws) return;
    
    ws.onmessage = function (e: any) {
      const eventDto = EventDto.parse(e.data);
      console.log(eventDto);

      switch (eventDto.eventType) {
        case EventType.ROOM_STATE: 
          setViewerCount(eventDto.payload.viewerCount);
          break;
        case EventType.JOIN_ROOM: 
          setViewerCount(v => v + 1);
          break;
        case EventType.LEAVE_ROOM:
          setViewerCount(v => v - 1);
          break;
        case EventType.CLOSE_ROOM:
          window.confirm("Room closed");
          history.push('/');
          break;
        case EventType.PUBLISH_QUIZ_GAME:
          setEndGame(false);
          setShowGameResult(false);
          setShowIntro(false);
          setQuizGameInfo(eventDto.payload);
          setPassQuizCount(eventDto.payload.passedQuizCount);
          setLeaderboard(null);
          setQuiz(null);
          setPoll(null);
          setClockSecond(0);
          setKahootState(undefined);
          setTotalAlive(-1);

          if (eventDto.payload.quizMode === 'KAHOOT') {
            setKahootState({
              rank: 0, totalScore: 0, correctStreak: 0
            });
          } else if (eventDto.payload.quizMode === 'CONFETTI') {
            setTotalAlive(0);
          }
          break;
        case EventType.PUBLISH_QUIZ:
          setPassQuizCount(eventDto.payload.passedQuizCount);
          setQuiz(eventDto.payload);
          setClockSecond(eventDto.payload.duration);
          setCorrectAnsId(null);
          setQuizSelectionMap({});
          setDisabledQuiz(false);
          setLeaderboard(null);
          setEarnedScore(-1);
          quizRef.current = eventDto.payload;
          break;
        case EventType.SELECT_QUIZ_ANSWER:
          setQuizSelectionMap((prev: any) => {
            const result = {...prev};
            const { clientId, quizAnswerIds } = eventDto.payload;
            Object.keys(result).forEach(ansId => {
              if (!!result[ansId]) {
                delete result[ansId][clientId];
              }
            });

            quizAnswerIds.forEach((quizAnswerId: any) => {
              if (!result[quizAnswerId]) {
                result[quizAnswerId] = {};
              }
              result[quizAnswerId][clientId] = true;
            });

            return result;
          });
          break;
        case EventType.SELECT_POLL_OPTION:
          break;
        case EventType.QUIZ_CLOCK_TICK:
          setClockSecond(eventDto.payload.atSecond);
          break;
        case EventType.END_QUIZ:
          setDisabledQuiz(true);
          setQuizSelectionMap(eventDto.payload.answerSelections);
          
          if (eventDto.payload.quizMode === 'CONFETTI') {
            const { confetti } = eventDto.payload;
            setCorrectAnsId(confetti.correctAnswerId);
            setTotalAlive(confetti.aliveClientCount);
            setAlive(confetti.alive);
          } else if (eventDto.payload.quizMode === 'KAHOOT') {
            const { kahoot } = eventDto.payload;
            setCorrectAnsId(kahoot.correctAnswerIds[0]);
            setKahootState({
              rank: kahoot.rank,
              totalScore: kahoot.totalScore,
              correctStreak: kahoot.correctStreak
            });
            setEarnedScore(kahoot.score);
          } else if (eventDto.payload.quizMode === 'FOOTBALL') {
            // do something
          }
          break;
        case EventType.QUIZ_LEADERBOARD:
          setQuiz(null);
          setLeaderboard({
            ...eventDto.payload,
            page: eventDto.payload.page + 1
          });
          break;
        case EventType.KAHOOT_QUIZ_STATE:
          setKahootState(eventDto.payload);
          break;
        case EventType.CONFETTI_QUIZ_STATE:
          setAlive(eventDto.payload.alive);
          break;
        case EventType.QUIZ_ANSWER_SELECTIONS:
          setQuizSelectionMap(eventDto.payload.answerSelections);
          break;
        case EventType.END_QUIZ_GAME:
          setQuiz(null);
          setEndGame(true);
          break;
        case EventType.SHOW_QUIZ_GAME_RESULT:
          setShowGameResult(true);
          break;
        case EventType.PUBLISH_POLL:
          setShowIntro(false);
          setPoll(eventDto.payload);
          setPollSelectionMap(null);
          break;
        case EventType.END_POLL:
          setPoll(null);
          break;
        case EventType.POLL_OPTION_SELECTIONS:
          setPollSelectionMap(eventDto.payload.optionSelections);
          break;
      }
    }
  }, [ws, roomId, clientInfo, history, quizGameInfo]);

  const handleChangeQuizAnswer = useCallback((ansId: string) => {
    if (ws && quiz) {
      const eventDto = new EventDto(EventType.SELECT_QUIZ_ANSWER, {
        quizId: quiz?.id,
        quizAnswerIds: [ansId]
      });
      ws.send(eventDto.toString());
    }
  }, [ws, quiz]);

  const handleFetchWinnerList = useCallback((page: number) => {
    if (ws) {
      const eventDto = new EventDto(EventType.PAGINATE_QUIZ_LEADERBOARD, { page, pageSize: leaderboard.pageSize }); // 0 based
      ws.send(eventDto.toString());
    }
  }, [ws, leaderboard]);

  const handleChangePollOption = useCallback((pollOptionIds: number[]) => {
    if (ws) {
      console.log('Poll options change', pollOptionIds)
      const eventDto = new EventDto(EventType.SELECT_POLL_OPTION, {
        pollId: poll?.id,
        pollOptionIds
      });
      ws.send(eventDto.toString());
    }
  }, [ws, poll]);

  const isConfetti = quizGameInfo?.quizMode === 'CONFETTI';
  const isFootball = quizGameInfo?.quizMode === 'FOOTBALL';

  return (
    <div className="p-4 relative h-full">
      <div className="flex w-full">
        <div className="rounded bg-red-600 text-white px-2 h-6 flex items-center">
          LIVE
        </div>
        <div className="ml-2 rounded bg-gray-700 text-white px-2 h-6 flex items-center opacity-75">
          <EyeFilled className="mr-1" /> {viewerCount}
        </div>
        <div className="ml-auto flex items-center">
          <b className="w-32 h-6 overflow-hidden whitespace-nowrap">{clientInfo?.name} - #{roomId}</b>
          <div className="rounded-full ml-2 h-8 w-8 bg-gray-700">
            {clientInfo?.avatar && <img className="rounded-full w-full h-full oveflow-hidden" alt="Avatar" src={clientInfo?.avatar} />}
          </div>
        </div>
      </div>

      {showIntro && (
        <RoomIntro />
      )}

      <div className="flex mt-4 justify-center">
        {!!quizGameInfo && (
          <>
            <div className="rounded py-1 px-3 border-2 font-bold border-blue-400 text-blue-400 mr-2">
              {QuizModeName[quizGameInfo.quizMode]}
            </div>
            <div className="rounded py-1 px-3 border-2 font-bold border-green-400 text-green-400">
              Câu {passedQuizCount} / {quizGameInfo.totalQuiz}
            </div>
          </>
        )}
      </div>

      {isConfetti && (
        <div className="flex mt-4 justify-center">
          <div 
            className={
              "ml-2 py-1 px-3 border-2 flex items-center rounded "
              + (alive ? "border-green-400 text-green-400" : "border-gray-400 text-gray-400")
            }
          >
            <FireFilled className="mr-1" /> {alive ? "Running" : "Fail"}
          </div>
          {totalAlive >= 0 && (
            <div className="ml-2 py-1 px-3 border-2 border-red-400 text-red-400 flex items-center rounded">
              <FireFilled className="mr-1" /> {totalAlive}
            </div>
          )}
        </div>
      )}

      {kahootState && (
        <div className="flex mt-4 justify-center">
          <div className="ml-2 py-1 px-3 border-2 border-red-400 text-red-400 flex items-center rounded">
            <OrderedListOutlined className="mr-1" /> {kahootState.rank}
          </div>
          <div className="ml-2 py-1 px-3 border-2 border-red-400 text-red-400 flex items-center rounded">
            <StarFilled className="mr-1" /> {kahootState.totalScore}
          </div>
          <div className="ml-2 py-1 px-3 border-2 border-red-400 text-red-400 flex items-center rounded">
            <FireFilled className="mr-1" /> {kahootState.correctStreak}
          </div>
        </div>
      )}

      {endGame && (
        <div className="mt-4">
          <Alert showIcon type="info" message="Trò chơi đã kết thúc" />
        </div>
      )}

      {showGameResult && (
        
        <div className={"mt-4 w-full p-4 flex flex-col border rounded-lg " + (isConfetti && !alive ? "border-red-400 bg-red-100" : "border-green-400 bg-green-100")}>
          {isConfetti ? (
            <h1>{alive ? "Yay, Bạn là người thắng cuộc" : "Thua keo này ta bày keo khác >\"<"}</h1>
          ) : (
            isFootball ? (
              <>
                <p className="mb-2 text-center">Thứ hạng của bạn là: <b>{kahootState?.rank || 0}</b></p>
                <p className="mb-0 text-center">Tổng số điểm bạn đạt được: <b>{kahootState?.totalScore || 0}</b></p>
              </>
            ) : (
              <p>Game đã kết thúc, bạn vui lòng đợi kết quả từ ban tổ chức</p>
            )
          )}
        </div>
      )}

      {clockSecond > 0 && (
        <div className="mt-10 flex justify-center">
          <h3 className="text-red-600 border-red-600 text-xl rounded-full border w-16 h-16 flex items-center justify-center">{clockSecond}</h3>
        </div>
      )}

      {quiz && (
        <div className="mt-5">
          <Quiz 
            disabled={disabledQuiz} 
            quiz={quiz} 
            onChange={handleChangeQuizAnswer} 
            correctAnsId={correctAnsId} 
            selectionMap={quizSelectionMap}
            score={earnedScore} />
        </div>
      )}
      {leaderboard && (
        <div className="mt-10">
          <QuizLeaderboard {...leaderboard} onPageChange={handleFetchWinnerList} />
        </div>
      )}
      {poll && (
        <Poll 
          poll={poll} 
          selectionMap={pollSelectionMap} 
          onChange={handleChangePollOption}
          onClose={() => setPoll(null)} />
      )}

      <div className={"absolute bottom-0 left-0 right-0 text-sm py-1 text-white text-center rounded-b-lg " + connectionStatusColors[status]}>
        {connectionStatusLabel[status]}
      </div>
    </div>
  )
}