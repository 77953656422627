import { Empty, Pagination } from "antd";
import { ClientInfoDto } from "../../shared/dtos/room/ClientInfoDto";

interface QuizLeaderboardProps {
  content: QuizLeaderboardRow[];
  page: number;
  pageSize: number;
  total: number;
  onPageChange?: (page: number) => any;
}

interface QuizLeaderboardRow {
  clientInfo: ClientInfoDto;
  totalScore: number;
  rank: number;
}

export function QuizLeaderboard(props: QuizLeaderboardProps) {
  return (
    <div className="border border-yellow-400 bg-yellow-50 rounded py-10">
      <h1 className="mb-6 text-center text-yellow-400 text-xl font-bold">Bảng xếp hạng</h1>
      {props.content.length === 0 ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <>
          <div className="px-12 max-h-64 overflow-auto">
            {props.content.map(row => (
              <div key={row.clientInfo.id} className="my-2 flex items-center rounded-lg border border-yellow-400 bg-yellow-100 px-2 py-1">
                {!isNaN(row.rank) && row.rank >= 0 && <div className="mr-2 text-yellow-700 rounded-full w-6 h-6 border border-yellow-400 flex items-center justify-center text-xs">{row.rank}</div>}
                <div className="rounded-full h-8 w-8 bg-gray-700">
                  {row.clientInfo.avatar && (
                    <img className="rounded-full w-full h-full oveflow-hidden" src={row.clientInfo.avatar} alt={row.clientInfo.name} />
                  )}
                </div>
                <div className="ml-2 text-yellow-700 font-bold">{row.clientInfo.name}</div>
                {!isNaN(row.totalScore) && row.totalScore >= 0 && <div className="ml-auto text-yellow-700 font-bold border rounded-lg border border-yellow-400 px-2">{row.totalScore}</div>}
              </div>
            ))}
          </div>

          <div className="mt-6 flex items-center justify-center">
            <Pagination 
              total={props.total}
              current={props.page}
              pageSize={props.pageSize}
              showSizeChanger={false}
              onChange={(page: number) => {
                if (page !== props.page) {
                  props.onPageChange && props.onPageChange(page);
                }
              }} />
          </div>
        </>
      )}
    </div>
  )
}