import { StringUtils } from "./StringUtils";

const { JSEncrypt } = require('jsencrypt');

export class RSAUtils {

  private static readonly jse = new JSEncrypt();

  static setPublicKey(publicKey: string) {
    const pem = this.toPublicKeyPem(publicKey);
    this.setPublicKeyPem(pem);
  }

  static setPublicKeyPem(publicKeyPem: string) {
    this.jse.setPublicKey(publicKeyPem);
  }

  static encrypt(message: string) {
    return this.jse.encrypt(message);
  }

  static toPublicKeyPem(publicKey: string) {
    return '-----BEGIN PUBLIC KEY-----\n' + StringUtils.splitLines(publicKey, 64) + '\n-----END PUBLIC KEY-----';
  }
}