import { EventType } from "../enums/EventType";

export class EventDto {

  public eventType: EventType;
  public payload: any;

  constructor(eventType: EventType, payload: any) {
    this.eventType = eventType;
    this.payload = payload;
  }

  static parse(text: string): EventDto {
    const eventType = text.substr(0, 4) as any;
    const payload = JSON.parse(text.substr(5));
    return { eventType, payload };
  }

  public toString() {
    return this.eventType + "_" + JSON.stringify(this.payload);
  }  
}