import { Switch, Route } from "react-router-dom";
import { RoomInputPage } from "./modules/room/RoomInputPage";
import { RoomPage } from "./modules/room/RoomPage";

export function AppRoutes() {
  return (
    <Switch>
      <Route exact path="/" component={RoomInputPage} />
      <Route path="/room/:roomId" component={RoomPage} />
    </Switch>
  )
}