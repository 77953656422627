export class StringUtils {

  static splitLines(text: string, length: number) {
    let result = '';
    while (text.length > 0) {
      if (result.length > 0) {
        result += '\n';
      }
      result += text.slice(0, length);
      text = text.slice(length);
    }
    return result;
  }
}